import {
  Select,
  MenuItem,
  Grid,
  Container,
  ThemeProvider,
  createTheme,
  Typography,
  Box,
  InputLabel,
  Button,
  Stack,
  CircularProgress,
  Zoom,
} from '@mui/material';
import {
  ShoppingCart as ShoppingCartIcon,
} from '@mui/icons-material';
import { useState } from 'react';

function DaDoFit() {

  const phones = [
    { label: 'iPhone 13 Pro (灰)', image: require('./img/i13pro/13pro灰.png') },
    { label: 'iPhone 13 Pro (金)', image: require('./img/i13pro/13pro金.png') },
    { label: 'iPhone 13 Pro (銀)', image: require('./img/i13pro/13pro銀.png') },
    { label: 'iPhone 13 Pro (綠)', image: require('./img/i13pro/13pro綠.png') },
    { label: 'iPhone 13 Pro (銀)', image: require('./img/i13pro/13pro銀.png') },
    { label: 'iPhone 13 Pro (藍)', image: require('./img/i13pro/13pro藍.png') },
  ];

  const backplanes = [
    { label: '無', image: null },
    {
      label: '狸貓與狸與狸',
      image: require('./img/i13pro/mou-RA007狸貓與狸與狸.png'),
      link: 'https://www.dado3c.com/products/mouki-ra-ra07'
    },
    {
      label: '喵齋卓木拉日峰之寫生',
      image: require('./img/i13pro/mou-RA008喵齋卓木拉日峰之寫生.png'),
      link: 'https://www.dado3c.com/products/mouki-ra-ra08'
    },
    {
      label: '喵齋桃花源之生日快樂',
      image: require('./img/i13pro/mou-RA009喵齋桃花源之生日快樂.png'),
      link: 'https://www.dado3c.com/products/mouki-ra-ra09'
    },
    {
      label: '喵齋酒精流氓',
      image: require('./img/i13pro/mou-RA010喵齋酒精流氓.png'),
      link: 'https://www.dado3c.com/products/mouki-ra-ra10'
    },
    {
      label: '萬歲萬萬歲鼠',
      image: require('./img/i13pro/mou-SU002萬歲萬萬歲鼠.png'),
      link: 'https://www.dado3c.com/products/mouki-su002'
    },
    {
      label: '泡泡鼠',
      image: require('./img/i13pro/mou-SU003泡泡鼠.png'),
      link: 'https://www.dado3c.com/products/mouki-su003'
    },
    {
      label: '甜甜圈鼠',
      image: require('./img/i13pro/mou-SU004甜甜圈鼠.png'),
      link: 'https://www.dado3c.com/products/mouki-su004'
    },
  ]

  const cases = [
    { label: '無', image: null },
    { label: '透明', image: require('./img/i13pro/13pro透明.png') },
    { label: '白框雙料', image: require('./img/i13pro/13pro白框雙料.png') },
    { label: '黑框雙料', image: require('./img/i13pro/13pro黑框雙料.png') },
  ]

  const [loading, setLoading] = useState(false);

  const [phoneIndex, setPhoneIndex] = useState(0);
  const [caseIndex, setCaseIndex] = useState(0);
  const [backplaneIndex, setBackplaneIndex] = useState(0);

  const [phoneImage, setPhoneImage] = useState(phones[phoneIndex].image);
  const [caseImage, setCaseImage] = useState(null);
  const [backplaneImage, setBackplaneImage] = useState(null);

  const handlePhoneChange = (e) => {
    setPhoneIndex(e.target.value);
    setLoading(true);
    const img = new Image();
    img.src = phones[e.target.value].image;
    img.onload = () => {
      setPhoneImage(img.src);
      setLoading(false);
    }
  }

  const handleCaseChange = (e) => {
    setCaseIndex(e.target.value);
    if (cases[e.target.value].image === null) {
      setCaseImage(null);
      return;
    }
    setLoading(true);
    // Loading image
    const img = new Image();
    img.src = cases[e.target.value].image;
    img.onload = () => {
      setCaseImage(img.src);
      setLoading(false);
    }
  }

  const handleBackplaneChange = (e) => {
    setBackplaneIndex(e.target.value)
    if (backplanes[e.target.value].image === null) {
      setBackplaneImage(null);
      return;
    }
    setLoading(true);
    // Loading image
    const img = new Image();
    img.src = backplanes[e.target.value].image;
    img.onload = () => {
      setBackplaneImage(img.src);
      setLoading(false);
    }
  }

  const handleBuy = () => {
    if (backplanes[backplaneIndex].link != null) {
      window.open(backplanes[backplaneIndex].link, '_blank');
    }
  }

  return (
    <Container maxWidth="lg" >
      <Grid container spacing={2} pt={3}>
        <Grid item xs={12} display="flex" flexDirection="row" alignItems={"center"} justifyContent="center" mb={1}>
          <Box component="img" src={require('./img/dado3c.png')}
            sx={{ maxHeight: '64px', mr: 2 }} />
          <Typography variant="h4" component="h1">
            <b>DaDoFit</b><br/>手機試衣間
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display="flex" alignItems="center" justifyContent="center"
            sx={{ backgroundColor: 'rgba(255,255,255, 0.05)', height: '500px', borderRadius: '24px' }}>
            {
              loading ?
                <CircularProgress />
                :
                <>
                  <Zoom in={!loading} >
                    <Box component={'img'}
                      sx={{ position: 'absolute', height: '450px' }}
                      draggable={false}
                      src={phoneImage}
                    />
                  </Zoom>
                  <Zoom in={!loading} >
                    <Box component={'img'}
                      sx={{ position: 'absolute', height: '450px' }}
                      draggable={false}
                      src={caseImage}
                    />
                  </Zoom>
                  <Zoom in={!loading} >
                    <Box component={'img'}
                      sx={{ position: 'absolute', height: '450px' }}
                      draggable={false}
                      src={backplaneImage}
                    />
                  </Zoom>
                </>
            }
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2} p={2}>
            <Typography variant="h6" component="div" gutterBottom>
              請選擇您的樣式
            </Typography>
            {/* 型號 */}
            <InputLabel>
              型號:&nbsp;
              <Select
                value={phoneIndex}
                onChange={handlePhoneChange}
              >
                {phones.map((item, index) => (
                  <MenuItem key={index} value={index}>{item.label}</MenuItem>
                ))}
              </Select>
            </InputLabel>
            {/* 手機殼 */}
            <InputLabel>
              手機殼:&nbsp;
              <Select
                value={caseIndex}
                onChange={handleCaseChange}
              >
                {cases.map((item, index) => (
                  <MenuItem key={index} value={index}>{item.label}</MenuItem>
                ))}
              </Select>
            </InputLabel>
            {/* 背板 */}
            <InputLabel>
              背板:&nbsp;
              <Select
                value={backplaneIndex}
                onChange={handleBackplaneChange}
              >
                {backplanes.map((item, index) => (
                  <MenuItem key={index} value={index}>{item.label}</MenuItem>
                ))}
              </Select>
            </InputLabel>
          </Stack>
          <Box display="flex" alignItems="end" justifyContent="end">
            <Button variant="outlined" size="large" onClick={handleBuy}>前往選購&nbsp;<ShoppingCartIcon /></Button>
          </Box>
        </Grid>
        <Grid item xs={12} m={5}>
          <Typography variant="body2" component="div" gutterBottom align="center">
            Powered by <a href="https://www.dado3c.com" target="_blank" rel="noreferrer noopener"><b>DaDo3C</b></a>.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#7b92fa',
      },
      secondary: {
        main: '#19857b',
      },
      mode: 'dark',
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingTop: '5px',
            paddingBottom: '5px',
          },
        },
        defaultProps: {
          size: 'small',
        }
      },
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <DaDoFit />
    </ThemeProvider>
  );
}

export default App;
